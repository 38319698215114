import { useEffect, useState } from "react";
import { getItem } from "../../../service/LocalStorage";
import { NavLink, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";

const {REACT_APP_API_URL} = process.env;
const {REACT_APP_PUSH_SERVER_URL} = process.env;


function NbConversation({componentSource, currentRoute}) {
    const navigate = useNavigate();
    let userToken = getItem('userToken');
    let tToken = jwtDecode(userToken).t_token;

    const [nbUnreadConversation, setNbUnreadConversation] = useState(0);
    const {t} = useTranslation();

      useEffect(() => {    
        async function getNbUnreadConversation() {
            try {
                const response = await fetch(`${REACT_APP_API_URL}/api/conversations/nb-unread`, {
                    method: "GET",
                    headers: { 
                        "Content-Type" : "application/json",
                        "Authorization": "Bearer "+ userToken 
                    }
                })
                
                if(response.status === 404) {
                    navigate('/user-not-found');
                }
    
                const data = await response.json();
    
                setNbUnreadConversation(data.nb);
            } catch (err) {} 
        }


        if(currentRoute) {
            if(currentRoute.includes("/conversations/")) {
                setTimeout(function() {
                    getNbUnreadConversation();
                }, 1000);
            } else {
                getNbUnreadConversation();
            }
        }             
      }, [currentRoute]);


    useEffect(() => {
        let subscribeURL = new URL(`${REACT_APP_PUSH_SERVER_URL}`);
        subscribeURL.searchParams.append("topic", 'topic/conversations/'+tToken);        

        let source = new EventSource(subscribeURL.href);
        
        source.addEventListener('open', () => {
        });
        source.addEventListener('message', (e) => {
            let data = JSON.parse(e.data);
            setNbUnreadConversation(data.nb);
        });
    
        source.addEventListener('error', (e) => {
        });

        return () => {
            source.close();
        };
        
      }, []);

    if(componentSource === "mobilefooter") {
        return (
            <NavLink to="/conversations">
                <li className={currentRoute === "/conversations" ? "list-inline-item mobile-footer-active" : "list-inline-item"} style={(nbUnreadConversation > 0  && currentRoute !== "/conversations") ? {color:"#f8abd6"} : {}}>
                    <i className="icofont-ui-messaging icofont-2x"></i> {nbUnreadConversation > 0 ? nbUnreadConversation : ""}
                </li>                        
            </NavLink>
        )
    }

    return (
        <>
        {
            nbUnreadConversation > 0 ? (
                    <NavLink to="/conversations" style={{color: "#f24570"}}><i className="icofont-ui-messaging"></i>&nbsp;{nbUnreadConversation}&nbsp;{t('conversations')}</NavLink>
            ) : (
                    <NavLink to="/conversations"><i className="icofont-ui-messaging"></i>&nbsp;{t('conversations')}</NavLink>
            )
        }
        </>
    )
}

export default NbConversation
import { Fragment, useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Auth from "../../context/Auth";
import LightMode from "../../context/LightMode";
import { logout } from "../../service/AuthApi";
import { getItem, setItem } from "../../service/LocalStorage";
import NbConversation from "./header/nbConversation";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useWindowSize } from "../../service/Window";
import { getDeviceId } from "../../service/Device";
import { getHeadersApiListingUsers } from "../../service/User";
import { getStatus } from "../../service/AppTrackingTransparency";
import LogoTevif from "./header/logoTevif";
import { Capacitor } from "@capacitor/core";
import { hideBanner } from "../adMob/adMob";
import ShowNotificationUser from "../notification/ShowNotificationUser";

const {REACT_APP_API_URL, REACT_APP_S_B} = process.env;

let langs = [
    {
        code: "de",
        lang: "de",
        label: "Deutsch",
        imgPath: `https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/png100px/de.png`
    },
    {
        code: "es",
        lang: "es",
        label: "Español",
        imgPath: `https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/png100px/es.png`
    },
    {
        code: "fr",
        lang: "fr",
        label: "Français",
        imgPath: `https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/png100px/fr.png`
    },
    {
        code: "gb",
        lang: "en",
        label: "English",
        imgPath: `https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/png100px/gb.png`
    },
    {
        code: "it",
        lang: "it",
        label: "Italiano",
        imgPath: `https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/png100px/it.png`
    },
    {
        code: "ru",
        lang: "ru",
        label: "Русский",
        imgPath: `https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/png100px/ru.png`
    },
    {
        code: "sa",
        lang: "ar",
        label: "عربي",
        imgPath: `https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/png100px/sa.png`
    },
    {
        code: "tr",
        lang: "tr",
        label: "Türkçe",
        imgPath: `https://${REACT_APP_S_B}.s3.eu-west-3.amazonaws.com/flags/png100px/tr.png`
    }
];

function HeaderTwo() {    
    const navigate = useNavigate();
    const currentLanguage = getItem('i18nextLng') ?? "en";
    
    const { isAuthenticated, setIsAuthenticated } = useContext(Auth);
    const { isLightMode, setIsLightMode } = useContext(LightMode);

    const [positionCountry, setPositionCountry] = useState(/*getItem("positionCountry") ??*/ "");
    const [positionCity, setPositionCity] = useState(/*getItem("positionCity") ??*/ "");
    const [mustCompleteProfile, setMustCompleteProfile] = useState(false);
    const [width, height] = useWindowSize();
    const [openPopUpNotif, setOpenPopUpNotif] = useState(0);

    const [nbUnViewedNotification, setNbUnViewedNotification] = useState("0");
    
    let positionCityName = "";

    let mapZoom = 5;
    let positionLat = getItem("positionLat") ?? "";
    let positionLng = getItem("positionLng") ?? "";
    
    if(positionLat && positionLng) {
        mapZoom = 10;
    }

    const linkMap = `/members-in-a-map?gender=&age_from=18&age_to=60&country=${positionCountry}&lat=${positionLat}&lng=${positionLng}&mapZoom=${mapZoom}`;
    const linkClassic = `/members-classic-mode?gender=&age_from=18&age_to=60&country=${positionCountry}&city=${positionCity}&cityName=${positionCityName}&page=1`;

    const handleLogout = () => {
        const statusPermission = getStatus();
        statusPermission.then((permissionData) => {
          if(permissionData.status === "notiosapp" || permissionData.status != "denied") {
            let deviceId = getDeviceId();
            try {
                deviceId.then((value) => {
                    const apiHeaders = getHeadersApiListingUsers();
                    fetch(`${REACT_APP_API_URL}/api/devices/${value.identifier}/delete`, {
                        method: "DELETE",
                        headers: apiHeaders,
                        }).then(() => {
                            logout();
                            setIsAuthenticated(false);
                            navigate('/');
                        }).catch((error) => {
                            logout();
                            setIsAuthenticated(false);
                            navigate('/');
                        });
                    ;
                });
            } catch(error) {
                logout();
                setIsAuthenticated(false);
                navigate('/');
            }
          } else {
            logout();
            setIsAuthenticated(false);
            navigate('/');
          }
        });
    }

    const menuTrigger = () => {
        document.querySelector('.menu').classList.toggle('active');
        document.querySelector('.header-bar').classList.toggle('active');

        if(Capacitor.getPlatform() !== 'web') {
            hideBanner();
        }
    }

    const {t} = useTranslation();
    
    const handleLightMode = () => {
        setIsLightMode(true);
        setItem('lightMode', 1);
    }

    const handleBlueMode = () => {
        setIsLightMode(false);
        setItem('lightMode', 0);
    }

    useEffect(() => {
        if(isAuthenticated) {
            var mustCompleteProfileBool = getItem("mustCompleteProfile") ?? true;
            
            if(mustCompleteProfileBool) {
                let userToken = getItem('userToken');
                fetch(`${REACT_APP_API_URL}/api/users/complete-profile`, {
                    method: "GET",
                    headers: { 
                        "Content-Type" : "application/json",
                        "Authorization": "Bearer "+ userToken 
                    }
                })
                .then(response => response.json())
                .then((result) => {
                    if(result.is_complete === true) {
                        setItem("mustCompleteProfile", false);
                        setMustCompleteProfile(false);
                    } else {
                        setItem("mustCompleteProfile", true);
                        setMustCompleteProfile(true);
                    }
                })
            }
        }
    }, [isAuthenticated]);

    const toggleOpenPopUpNotif = () => {
        setOpenPopUpNotif(prevState => prevState === 0 ? 1 : 0);
    };

    const apiHeaders = getHeadersApiListingUsers();

    useEffect(() => {
        if(isAuthenticated) {
            fetch(`${REACT_APP_API_URL}/api/notification-user/get-nb-un-viewed`, {
                method: "GET",
                headers: apiHeaders,
                }).then(response => response.json())
                .then((result) => {
                    setNbUnViewedNotification(result.nb);
                }); 
        }
      }, [])


    return (
        <Fragment>
            <header className="header-section" style={{position: "fixed", top:"0", zIndex: "1000"}}>
                <div className="header-bottom bg-primary">
                    <div className="container">
                        <div className="header-wrapper" style={isAuthenticated ? {padding: "20px 0"}: {}}>
                            <div className="logo" style={(process.env.REACT_APP_IS_IOS_APP === "true" && width < 576) ? {marginTop: "20px"} : {}}>
                                <LogoTevif />
                            </div>
                            <div className="menu-area" style={(process.env.REACT_APP_IS_IOS_APP === "true" && width < 576 )? {marginTop: "20px"} : {}}>
                                <ul className="menu" style={{maxHeight: "600px"}}>
                                    {isAuthenticated ? (
                                        <>
                                            {width > 991 ? (
                                                    <>
                                                        <li><NavLink to="/news"><i className="icofont-ui-home"></i>&nbsp;{t('home')}</NavLink></li>
                                                        <li className="menu-item-has-children">
                                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10"> {t('research')}</a>
                                                            <ul className="submenu dropdown-menu">
                                                                <li><NavLink to={linkMap}><span><i className="icofont-globe-alt"></i>&nbsp;{t('in_map')}</span></NavLink></li>
                                                                <li><NavLink to={linkClassic}><span><i className="icofont-search-1"></i>&nbsp;{t('classic')}</span></NavLink></li>
                                                                {/*<li><NavLink to="/search-all-members"><span><i className="icofont-globe-alt"></i>&nbsp;{t('by_username')}</span></NavLink></li>*/}
                                                            </ul>
                                                        </li>                                            
                                                        <li>
                                                            <NbConversation
                                                                componentSource={"webheader"} 
                                                                currentRoute={"hello-moto"}
                                                             />
                                                        </li>
                                                        <li onClick={toggleOpenPopUpNotif} style={nbUnViewedNotification !== "0" ? {cursor: "pointer", color: "#f24570"}: {cursor: "pointer"}}>
                                                            <i className="icofont-notification"></i> 
                                                            {nbUnViewedNotification !== "0" ? (nbUnViewedNotification) : null} {t('notifications')}
                                                        </li>
                                                    </>
                                                ) : null}
                                            
                                            {
                                                mustCompleteProfile ? (
                                                    <li><NavLink to="/profile?editProfile=1" style={{color: "#f24570"}}><i className="icofont-exclamation-circle"></i>&nbsp;{t('complete_your_profile')}</NavLink></li>
                                                ) : null
                                            }

                                            {width <= 991 ? (
                                                <>
                                                    <li><NavLink to="/validate-profile"><span>{t('validate_my_account')}</span><i className="icofont-check verified-user" title="Verify Profile"></i></NavLink></li>
                                                    <li><NavLink to="/contact"><span><i className="icofont-envelope"></i>&nbsp;{t('contact_us')}</span></NavLink></li>
                                                    <li><NavLink to="/policy"><span>{t('privacy_policy')}</span></NavLink></li>

                                                    {/* MODE */}
                                                    {isLightMode ? (<li><a href="#" onClick={handleBlueMode}><span><i className="icofont-moon"></i>&nbsp;{t('dark_mode')}</span></a></li>) : (<li><a href="#" onClick={handleLightMode}><span><i className="icofont-sun"></i>&nbsp;{t('light_mode')}</span></a></li>)}
                                                    {/* MODE */}

                                                    <li><NavLink onClick={handleLogout}><span><i className="icofont-logout"></i> {t('logout')}</span></NavLink></li>
                                                </>
                                            ) : (
                                                <li className="menu-item-has-children">
                                                    <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10"> {t('profile')}</a>
                                                    <ul className="submenu dropdown-menu">
                                                        <li><NavLink to="/profile"><span><i className="icofont-user"></i>&nbsp;{t('my_profile')}</span></NavLink></li>
                                                        <li><NavLink to="/validate-profile"><span>{t('validate_my_account')}</span><i className="icofont-check verified-user" title="Verify Profile"></i></NavLink></li>
                                                        <li><NavLink to="/contact"><span><i className="icofont-envelope"></i>&nbsp;{t('contact_us')}</span></NavLink></li>
                                                        <li><NavLink to="/policy"><span>{t('privacy_policy')}</span></NavLink></li>
                                                        {/* MODE */}
                                                        {isLightMode ? (<li><a href="#" onClick={handleBlueMode}><span><i className="icofont-moon"></i>&nbsp;{t('dark_mode')}</span></a></li>) : (<li><a href="#" onClick={handleLightMode}><span><i className="icofont-sun"></i>&nbsp;{t('light_mode')}</span></a></li>)}
                                                        {/* MODE */}
                                                        <li><a href="#" onClick={handleLogout}><span><i className="icofont-logout"></i> {t('logout')}</span></a></li>
                                                    </ul>
                                                </li>
                                            )}
                                        </>
                                    ) : 
                                        <>
                                            <li><NavLink to="/login">{t('login')}</NavLink></li>                                            
                                            <li><NavLink to="/contact">{t('contact_us')}</NavLink></li>
                                            <li><NavLink to="/policy">{t('privacy_policy')}</NavLink></li>

                                            {/* MODE */}
                                            {isLightMode ? (<li><a href="#" onClick={handleBlueMode}><span><i className="icofont-moon"></i>&nbsp;{t('dark_mode')}</span></a></li>) : (<li><a href="#" onClick={handleLightMode}><span><i className="icofont-sun"></i>&nbsp;{t('light_mode')}</span></a></li>)}
                                            {/* MODE */}
                                        </>
                                    }

                                    <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10"><i className="icofont-globe"></i></a>
                                        <ul className="submenu dropdown-menu">
                                            {langs.map((lang, i) => (
                                                <li key={`li_lang_${i}`}>
                                                    <a onClick={() => i18next.changeLanguage(lang.lang)} disabled={lang.label === currentLanguage}>
                                                        <span style={{opacity: currentLanguage === lang.lang ? "0.5" : "1",  color: "var(--body-txt-color)"}}>
                                                            <img src={lang.imgPath} style={{width: "25px"}} />
                                                            &nbsp;
                                                            {lang.label}
                                                        </span>
                                                    </a>
                                                </li> 
                                            ))}
                                        </ul>
                                    </li>
                                </ul>
                                {!isAuthenticated ? (
                                    <>
                                        <a className="login" onClick={() => navigate("/login")}><i className="icofont-user"></i> <span>{t('login')}</span> </a>
                                        <a className="signup" onClick={() => navigate("/signup")}><i className="icofont-users"></i> <span>{t('sign_up')}</span> </a>
                                    </>
                                ) :  (
                                    <>
                                        <div className="ellepsis-bar d-lg-none"  onClick={toggleOpenPopUpNotif}>
                                            <i className="icofont-notification" style={nbUnViewedNotification !== "0" ? {color: "#f24570"}: {}}></i> 
                                            {nbUnViewedNotification !== "0" ? (<span style={{fontSize: "10px",color: "#f24570"}}>{nbUnViewedNotification}</span>) : null}
                                        </div>
                                    </>
                                )}

                                <div className="header-bar d-lg-none" onClick={menuTrigger}>
                                    <span></span>
                                    <span></span>
                                    <span></span>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {openPopUpNotif !== 0 ? (
                <ShowNotificationUser 
                    openPopUpNotif={openPopUpNotif} 
                    setOpenPopUpNotif={setOpenPopUpNotif} 
                    nbUnViewedNotification={nbUnViewedNotification}
                />
            ) : null}
            
        </Fragment>
    );
    
}
 
export default HeaderTwo;
import { useEffect, useRef } from "react";
import Hls from "hls.js";

export default function VideoActivity({ path, poster }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Vérifier si le navigateur supporte nativement HLS
      if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        // Si c'est le cas, on définit simplement le src
        videoRef.current.src = path;
      } else if (Hls.isSupported()) {
        // Sinon, utiliser hls.js pour lire le flux HLS
        const hls = new Hls();
        hls.loadSource(path);
        hls.attachMedia(videoRef.current);
      }
    }

    let options = {
      rootMargin: "0px",
      threshold: 1
    };

    let handlePlay = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoRef.current.play()
            .then(() => {}).catch(error => {
            });   
        } else {
          if (videoRef && videoRef.current) {
            videoRef.current.pause();
          }
        }
      });
    };

    let observer = new IntersectionObserver(handlePlay, options);
    observer.observe(videoRef.current);

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [path]);

  return (
    <video
      width="100%"
      preload="none"
      controls
      tabIndex="-1"
      playsInline
      aria-label="Vidéo intégrée"
      poster={poster}
      style={{
        maxHeight: "500px",
        borderRadius: "10px"
      }}
      ref={videoRef}
      controlsList="nodownload"
    >
    </video>
  );
}

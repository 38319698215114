import { PhotoProvider, PhotoView } from "react-photo-view";
import VideoActivity from "./video";
import VideoHlsActivity from "./videoHls";

export default function ShowMedia({media, path, styleObject, poster}) {
    if(media.mimeType == 1) {
        if(styleObject) {
            return (
                <PhotoProvider>
                    <PhotoView src={path}>
                        <img 
                            src={path} 
                            alt="img" 
                            style={styleObject} 
                        />
                    </PhotoView>
                </PhotoProvider>
            );
        } else {
            return (
                <PhotoProvider>
                    <PhotoView src={path}>
                        <img 
                            src={path} 
                            alt="img"
                        />
                    </PhotoView>
                </PhotoProvider>
            );
        }
    }
        
    
    if(media.mimeType == 2 && media.isLive) {
        return (
            <VideoHlsActivity
                styleObject={styleObject} 
                path={media.liveUrl}
                poster={poster}
            />
        );
    }

    if(media.mimeType == 2) {
        return (
            <VideoActivity
                styleObject={styleObject} 
                path={path}
                poster={poster}
            />
        );
    }

    return null
}

import { hasAuthenticated } from "./AuthApi";
import { getItem } from './LocalStorage';

const {REACT_APP_API_URL} = process.env;

export function getApiListingUsers() {
    const isAuthenticated = hasAuthenticated();
    if(isAuthenticated) {
        return '/api/users/list';
    }

    return '/api/users/public/list';
}

export function getHeadersApiListingUsers() {
    const isAuthenticated = hasAuthenticated();
    
    if(isAuthenticated) {
        let userToken = getItem('userToken');
        return { 
            "Content-Type" : "application/json",
            "Authorization": "Bearer "+ userToken 
        }
    }

    return { 
        "Content-Type" : "application/json"
    };
}

export async function getListUsers(gender, ageFrom, ageTo, country, page) {
    const response = await fetch(`${REACT_APP_API_URL}/api/users/public/list?gender=${gender}&age_from=${ageFrom}&age_to=${ageTo}&country=${country}&page=${page}`, 
        {
            method: "GET",
        }
    )
    const data = await response.json();
     
    return data;
}


export function getEmptyListUsers() {
    return {
        "context": "user",
        "totalItems": 0,
        "currentItems": 0,
        "totalPages": 0,
        "pagination": {
            "page": 1,
            "limit": 20
        },
        "order": {
            "field": "id",
            "direction": "DESC"
        },
        "parameters": {
            "gender": null,
            "search": null,
            "city": {
                "id": null,
                "name": null,
                "country": {
                    "id": null,
                    "name": null,
                    "code": null
                }
            }
        },
        "items": []
    };
}


export function getInitialMedias() {
    const initialMedia = {
        "totalMedia": 0,
        "videos": {
            "context": "medias",
            "totalItems": 0,
            "currentItems": 0,
            "totalPages": 0,
            "pagination": {
                "page": 1,
                "limit": 20
            },
            "order": {
                "field": "id",
                "direction": "DESC"
            },
            "parameters": {
                "userId": "",
                "mediaType": 2
            },
            "items": []
        },
        "images": {
            "context": "medias",
            "totalItems": 0,
            "currentItems": 0,
            "totalPages": 0,
            "pagination": {
                "page": 1,
                "limit": 20
            },
            "order": {
                "field": "id",
                "direction": "DESC"
            },
            "parameters": {
                "userId": "",
                "mediaType": 2
            },
            "items": []
        }
    };

    return initialMedia;
}
export function getInitialUserData() {
    const initialData = {
        "publicPathProfilePicture": "",
        "publicPathCropedProfilePicture": "",
        "profileImage": "",
        "id": null,
        "username": "",
        "email": "",
        "description": "",
        "gender": "",
        "firstName": "",
        "lastName": "",
        "dateOfBirth": "",
        "age": "",
        "job": "",
        "city": {
            "id": "",
            "name": "",
            "country": {
                "id": "",
                "name": "",
                "code": ""
            }
        },
        "skills": [],
        "similar": {
            "items": []
        },
        "followers": {
            "items": []
        },
        "languages": []
    };

    return initialData;
}

export function getStringGender(intValue) {
    return (intValue === 1) ? 'Man' : 'Women';
}


export function getListYears() {
    let list = [];
    const currentYear = new Date().getFullYear();
    for(let i = currentYear - 18; i >= currentYear - 88; i --) {
        list[i] = i;
    }

    return list;
}

export function getListMonths() {
    let list = [];
    for(let i = 1; i < 13; i ++) {
        list[i] = i;
    }

    return list;
}

export function getListDays() {
    let list = [];
    for(let i = 1; i < 32; i ++) {
        list[i] = i;
    }

    return list;
}


export function getAges() {
    let list = [];
    for(let i = 18; i <= 70; i ++) {
        list[i] = i;
    }

    return list;
}



export function emptyCollection() {
    let data = {
        "context": "",
        "totalItems": 0,
        "currentItems": 0,
        "totalPages": 0,
        "pagination": {
            "page": 1,
            "limit": 20
        },
        "order": {
            "field": "id",
            "direction": "DESC"
        },
        "parameters": {},
        "items": []
    };

    return data;
}


export function getMarkerUser(userPosition) {
    let style = (userPosition.gender === 1) ? '#custom_marker-m' : '#custom_marker-w' 
    const marker = new window.google.maps.Marker({ 
        position: {lat: userPosition.lat, lng: userPosition.lng}, 
        icon: {
            url: userPosition.urlIcon + style,
            scaledSize: new window.google.maps.Size(40, 40), // scaled size
            origin: new window.google.maps.Point(0,0), // origin
            anchor: new window.google.maps.Point(0, 0) // anchor
        }
    });

    return marker
}

import { Fragment, useContext, useEffect, useRef, useState } from "react";

import Footer from "../component/layout/mobilefooter";
import HeaderTwo from "../component/layout/headertwo";
import { useWindowSize } from "../service/Window";
import { getHeadersApiListingUsers } from "../service/User";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Clipboard } from '@capacitor/clipboard';
import { getItem } from "../service/LocalStorage";
import jwtDecode from "jwt-decode";
import Auth from "../context/Auth";
import ActivityBody from "./user/activity/activityBody";
import SignalActivity from "./user/activity/signal";
import InitializeAndResumeBanner from "../component/adMob/initializeBanner";
import TevifSpinner from "../component/spinner/tevifSpinner";
import ActivityShowLikes from "./user/activity/showLikes";
import { Helmet } from "react-helmet";
import { getLangFromUrl } from "../service/Language";
import CommentShowLikes from "./user/activity/showCommentLikes";
import AdTevif from "../component/adTevif/adTevif";

const {REACT_APP_API_URL, REACT_APP_IS_IOS_APP} = process.env;

export default function ShowActivity() {
    let { tokenActivity } = useParams();
    const {isAuthenticated, setIsAuthenticated} = useContext(Auth);

    let currentUserIdentifier = null
    if(isAuthenticated) {
        let userToken = getItem('userToken');
        currentUserIdentifier = jwtDecode(userToken).username;
    }

    const [width, height] = useWindowSize();
    const [copied, setCopied] = useState(false);

    const [activities, setActivities] = useState([]);
    const navigate = useNavigate();
    const apiHeaders = getHeadersApiListingUsers();
    
    function getActivity(tokenActivity) {
        let url = `${REACT_APP_API_URL}/api/public/activities/${tokenActivity}/show`; 
        if(isAuthenticated) {
            url = `${REACT_APP_API_URL}/api/activities/${tokenActivity}/show`; 
        } 

        fetch(url, {
            method: "GET",
            headers: apiHeaders,
        })
        .then(response => response.json())
        .then((result) => {
            if(result && result.code && result.code === 404) {
                navigate('/page-not-found');
            }
            setActivities([result]);
        }).catch((error) => {
        });
    }
   
    useEffect(() => {
        getActivity(tokenActivity);
    }, []);


    let marginValue = "35px";
    if (REACT_APP_IS_IOS_APP === "true") {
        marginValue = "55px";
    }

    const {t} = useTranslation();
    const currentLanguage = getItem('i18nextLng') ?? getLangFromUrl();
    const [idActivityShowLikes, setIdActivityShowLikes] = useState(null);
    const [idCommentShowLikes, setIdCommentShowLikes] = useState(null);

    let styleActivity = {maxWidth:"856px", margin:"auto"};
    if(width >= 1200) {
        styleActivity = {maxWidth:"856px", marginTop: "30px"};
    } else if (width > 991) {
        styleActivity = {maxWidth:"856px", marginTop: "70px"};
    } 

    let ogImage = null;
    if (activities.length > 0) {
        if(activities[0].publicPosterUrl) {
            ogImage = activities[0].publicPosterUrl;
        } else if (activities[0].media && activities[0].media.mimeType == 1) {
            ogImage = activities[0].media.publicPath;
        }
    }

    return (
        <Fragment>
        <HeaderTwo />
        <InitializeAndResumeBanner />

            <Helmet>
                    <title>Tevif | {t('social_media')} - {t('live_chat')} - {t('free_dating_site')}</title>
					<meta name="description" content={`${t("home_page_meta_description")}. ${t('contact_and_chat_with_women_and_men_online')}`} />
					<meta property="og:title" lang={currentLanguage} content={`Tevif | ${t('social_media')} - ${t('live_chat')} - ${t('free_dating_site')}`}></meta>
					<meta property="og:description" lang={currentLanguage} content={`${t("home_page_meta_description")}. ${t('contact_and_chat_with_women_and_men_online')}`}></meta>
                
                { ogImage != null ? (
                    <meta property="og:image" content={ogImage}/>
                ) : null}
			</Helmet>

    
        <section className="profile-section padding-tb banner-admob-margin" style={width < 576 ? {marginTop: marginValue} : {}}>
                <div className="container">
                    <div className="section-wrapper">
                        <div className="member-profile">
                            <div className="profile-details">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane activity-page fade show active" id="activity" role="tabpanel">
                                        <div className="col-xl-12" style={styleActivity}>
                                            <article>
                                                <div className="activity-tab">
                                                    <AdTevif pageName={'showActivity'} />

                                                    {activities.length > 0 ? (
                                                        <>
                                                            <div className="tab-content activity-content" id="pills-tabContent">
                                                                <div className="tab-pane fade show active" id="pills-personal" role="tabpanel"
                                                                    aria-labelledby="pills-personal-tab">               
                                                                    <div className="post-item mb-20" style={{borderRadius:"10px"}}>
                                                                        {isAuthenticated ? (
                                                                            <SignalActivity 
                                                                            activityId={activities[0].id} 
                                                                            currentUserIdentifier={currentUserIdentifier}
                                                                            activityUserIdentifier={activities[0].user.username}
                                                                            activities={activities}
                                                                            setActivities={setActivities}
                                                                        />
                                                                        ) : null}
                                                                        <ActivityBody 
                                                                            activity={activities[0]} 
                                                                            activities={activities} 
                                                                            setActivities={setActivities} 
                                                                            setIdActivityShowLikes={setIdActivityShowLikes}
                                                                            setIdCommentShowLikes={setIdCommentShowLikes}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ActivityShowLikes
                                                                idActivityShowLikes={idActivityShowLikes}
                                                                setIdActivityShowLikes={setIdActivityShowLikes}
                                                            />

                                                            <CommentShowLikes
                                                                idCommentShowLikes={idCommentShowLikes}
                                                                setIdCommentShowLikes={setIdCommentShowLikes}
                                                            />
                                                        </>
                                                    ): <TevifSpinner/>}
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
    </Fragment>
    );
}

import { NavLink, useLocation } from "react-router-dom"; 
import { useContext, useEffect, useState } from "react";
import { getItem } from "../../service/LocalStorage";
import Auth from "../../context/Auth";
import jwtDecode from "jwt-decode";
import NbConversation from "./header/nbConversation";

export default function Footer () { 
    const {isAuthenticated, setIsAuthenticated} = useContext(Auth);
    const [currentRoute, setCurrentRoute] = useState("");
    const [positionCountry, setPositionCountry] = useState(/*getItem("positionCountry") ??*/ "");
    

    let mapZoom = 5;
    let positionLat = getItem("positionLat") ?? "";
    let positionLng = getItem("positionLng") ?? "";
    
    if(positionLat && positionLng) {
        mapZoom = 10;
    }

    const location = useLocation();
    let userToken = getItem('userToken');
    let tToken = '';
    if (userToken) {
        tToken = jwtDecode(userToken).t_token;
    }

    const linkMap = `/members-in-a-map?gender=&age_from=18&age_to=60&country=${positionCountry}&lat=${positionLat}&lng=${positionLng}&mapZoom=${mapZoom}`;
    const linkClassic = `/members-classic-mode?gender=&age_from=18&age_to=60&country=${positionCountry}&city=&cityName=&page=1`;
    
    useEffect(() => {
        if(location) {
            setCurrentRoute(location.pathname);
        }
        
    }, [location]);

    
    return (
        <div className="footer-mobile-amimor">
            <footer>
                <ul className="list-inline">
                    <NavLink to={isAuthenticated ? "/news" : "/"} style={{cursor: "pointer"}}>
                        <li className={currentRoute === "/news" ? "list-inline-item mobile-footer-active" : "list-inline-item"}>
                            <i className="icofont-ui-home icofont-2x"></i>
                        </li>
                    </ NavLink>
                    <NavLink to={linkClassic}>
                        <li className={currentRoute === "/members-classic-mode" ? "list-inline-item mobile-footer-active" : "list-inline-item"}>
                        <i className="icofont-users icofont-2x"></i>
                        </li>
                    </NavLink>
                    <NavLink to={linkMap}>
                        <li className={currentRoute === "/members-in-a-map" ? "list-inline-item mobile-footer-active" : "list-inline-item"}>
                            <i className="icofont-globe-alt icofont-2x"></i>
                        </li>
                    </NavLink>
                    {/*<NavLink to="/search-all-members">
                        <li className={currentRoute === "/search-all-members" ? "list-inline-item mobile-footer-active" : "list-inline-item"}>
                            <i className="icofont-search-user icofont-2x"></i>
                        </li>
                    </NavLink>*/}
                    {isAuthenticated ? (
                            <>
                                <NbConversation 
                                    componentSource={"mobilefooter"} 
                                    currentRoute={currentRoute}
                                />
                                <NavLink to="/profile">
                                    <li className={currentRoute === "/profile" ? "list-inline-item mobile-footer-active" : "list-inline-item"}>
                                        <i className="icofont-user icofont-2x"></i>
                                    </li>
                                </NavLink>   
                            </>
                        ) : null
                    }

                    {/*<NavLink to="/pricing-plan">
                        <li className={currentRoute === "/pricing-plan" ? "list-inline-item mobile-footer-active" : "list-inline-item"}>
                            <i className="icofont-cop-badge icofont-2x"></i>
                        </li>
                    </NavLink>*/}  
                </ul>
            </footer>
        </div>

    );
}

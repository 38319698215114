export function removeItem(item) {
    window.localStorage.removeItem(item);
}


export function getItem(item) {
    return window.localStorage.getItem(item);
}


export function setItem(localStorageName, item) {
    window.localStorage.setItem(localStorageName, item);
}
import axios from 'axios';
import {removeItem, getItem, setItem} from './LocalStorage';
const {REACT_APP_API_URL} = process.env;

export function hasAuthenticated() {
    const token = getItem('userToken');
    const result = token ? tokenIsValid(token) : false;

    if(false === result) {
        removeItem('userToken');
    }

    return result;
}

export async function login(credentials) {
    await axios
        .post(`${REACT_APP_API_URL}/api/login_check`, credentials)
        .then(response => response.data.token)
        .then(token => {
            setItem('userToken', token);

            return true;
        })
    ;
}



export function loginNormal(credentials) {
    axios
        .post(`${REACT_APP_API_URL}/api/login_check`, credentials)
        .then(response => response.data.token)
        .then(token => {
            setItem('userToken', token);

            return true;
        })
    ;
}

export function logout() {
    removeItem('userToken');
}

function tokenIsValid(token) {
    return true;

    /*const {exp} = jwtDecode(token);

    if(exp * 1000 > new Date().getTime()) {
        return true;
    }

    return false;*/
}

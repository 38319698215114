import { Fragment, useState, useCallback, useEffect, useRef, useContext } from "react";
import { getAges, getMarkerUser } from "../service/User";
import { getCountriesApi, getCountryNameByLang } from "../service/CityApi";
import HeaderTwo from "../component/layout/headertwo";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import SuperClusterAlgorithm from "../util/superClusterAlgorithm";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import PositionUser from "./map/postionUser";
import { getItem } from "../service/LocalStorage";
import Footer from "../component/layout/mobilefooter";
import { useWindowSize } from "../service/Window";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Auth from "../context/Auth";
import UserData from "./user/search/userData";
import AppSectionTwo from "../component/section/appsectiontwo";
import { showInterstitial} from "../component/adMob/adMob";
import InitializeAndHideBanner from "../component/adMob/initializeAndHideBanner";
import TevifSpinner from "../component/spinner/tevifSpinner";
import { getProfileName } from "../service/String";
import AdTevif from "../component/adTevif/adTevif";

const {REACT_APP_API_URL, REACT_APP_GOOGLE_MAPS_KEY} = process.env;
const customMapStyles = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    // Ajoutez d'autres styles personnalisés en fonction de vos besoins
  ];

export default function MembersInMaps() {
    
    let containerStyle = {
        width: '100%',
        height: '700px'
    };
    
    const navigate = useNavigate();
    const currentLanguage = getItem('i18nextLng') ?? "en";

    const ref = useRef(); 
    const mapRef = useRef();       
    const markerCluster = useRef(null);

    const {isAuthenticated, setIsAuthenticated} = useContext(Auth); 

    const [map, setMap] = useState(null);
    const [usernameSelected, setUsernameSelected] = useState("");
    const [userSelected, setUserSelected] = useState("");
    const [countriesList, setCountriesList] = useState([]);    
    const [spinnerUserSelected, setSpinnerUserSelected] = useState(false);
    const [searchType, setSearchType] = useState("map");    
    
    const [queryParameters] = useSearchParams();
    const [filterGender,setFilterGender] = useState(queryParameters.get("gender")?? "");
    const [filterAgeFrom,setFilterAgeFrom] = useState(queryParameters.get("age_from") ?? "18");
    const [filterAgeTo,setFilterAgeTo] = useState(queryParameters.get("age_to") ?? "60");
    const [filterCountry,setFilterCountry] = useState(queryParameters.get("country") ?? "");    
    const [width, height] = useWindowSize();
    
    if(width < 576) {
        containerStyle = {
            width: '100%',
            height: '500px'
        }
    }

    const latMap = queryParameters.get("lat") ? parseFloat(queryParameters.get("lat")) : 46.2445941;
    const lngMap = queryParameters.get("lng") ? parseFloat(queryParameters.get("lng")) : 10.5974086;

    const [mapCenter,setMapCenter] = useState({
        lat: latMap,
        lng: lngMap
    });

    const [mapZoom,setMapZoom] = useState(queryParameters.get("mapZoom") ? parseFloat(queryParameters.get("mapZoom")) : 5);

    function getConfAndUrl() {
        let urlGetMarkers = '/api/public/google-maps-markers';
        let headersObject = { 
            "Content-Type" : "application/json" 
        };
    
        if(isAuthenticated) {
            let userToken = getItem('userToken');
    
            headersObject = { 
                "Content-Type" : "application/json",
                "Authorization": "Bearer "+ userToken 
            };
    
            urlGetMarkers = '/api/google-maps-markers';
        }

        return {
            url: urlGetMarkers,
            headers: headersObject
        };
    }
    

    const handleSubmit = async event => {
        event.preventDefault();
        
        markerCluster.current.clearMarkers();

        if(searchType === 'classic') {
            let path = `gender=${filterGender}&age_from=${filterAgeFrom}&age_to=${filterAgeTo}&country=${filterCountry}`;
            navigate(`/members-classic-mode?${path}`);    
        } else {
            navigate(`/members-in-a-map?gender=${filterGender}&age_from=${filterAgeFrom}&age_to=${filterAgeTo}&country=${filterCountry}`);
            try {
                let confAndUrl = getConfAndUrl();
                fetch(
                    `${REACT_APP_API_URL}${confAndUrl.url}?gender=${filterGender}&ageFrom=${filterAgeFrom}&ageTo=${filterAgeTo}&country=${filterCountry}`,
                    {
                        method: "GET",
                        headers: confAndUrl.headers
                    }
                )
                .then(response => response.json())
                .then((data) => {
                    if(filterCountry && data.parameters && data.parameters.country && data.parameters.country.capital_city) {
                        let capital = data.parameters.country.capital_city;
                        map.setZoom(5);
                        let latLng = new window.google.maps.LatLng(capital.lat, capital.lng);
                        map.setCenter(latLng);
                    }

                    let positions = data.items;
                    const markers = positions.map((position, i) => {
                        const marker = getMarkerUser(position);
                    
                        marker.addListener("click", () => {
                            setUsernameSelected(position.username);
                            });
                    
                        return marker;
                    });
        
                    
                    markerCluster.current.addMarkers(markers);

                    mapRef.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
                });
        
            } catch (err) {}
        }
    }


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY
    });

    const onLoad = useCallback((map) => {
        setMap(map);

        try {
            let confAndUrl = getConfAndUrl();

            fetch(
                `${REACT_APP_API_URL}${confAndUrl.url}?gender=${filterGender}&ageFrom=${filterAgeFrom}&ageTo=${filterAgeTo}&country=${filterCountry}`,
                {
                    method: "GET",
                    headers: confAndUrl.headers
                }
            )
            .then(response => response.json())
            .then((data) => {
                let hasLocationPermission = getItem('hasLocationPermission');
                if(!hasLocationPermission && filterCountry && data.parameters && data.parameters.country && data.parameters.country.capital_city) {
                    let capital = data.parameters.country.capital_city;
                    
                    map.setZoom(mapZoom);
                    let latLng = new window.google.maps.LatLng(capital.lat, capital.lng);
                    map.setCenter(latLng);
                }
                
                let positions = data.items;

                const markers = positions.map((position, i) => {
                    const marker = getMarkerUser(position);
                
                    marker.addListener("click", () => {
                        setUsernameSelected(position.username);
                    });

                    return marker;
                });

            
                markerCluster.current = new MarkerClusterer({
                    markers,
                    map,
                    algorithm: new SuperClusterAlgorithm({ radius: 100 }),
                });
            });
    
        } catch (err) {}        
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);
      

    useEffect(() => {    
        async function getUserSelected() {
            try {
                const userResponse = await fetch(`${REACT_APP_API_URL}/api/users/public/show/${usernameSelected}?allData=0`, {
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json",
                    }
                })
                
                if(userResponse.status === 404) {
                    setSpinnerUserSelected(false);
                    //window.location.replace('/user-not-found');
                }

                const userData = await userResponse.json();
                setUserSelected(userData);
                setSpinnerUserSelected(false);
            } catch (err) {
                setSpinnerUserSelected(false);
                setUsernameSelected("");
            } 
        }
        
        if(usernameSelected) {
            setSpinnerUserSelected(true);
            getUserSelected();
        }

    }, [usernameSelected]);


    const [nbUserClicked, setNbUserClicked] = useState(0);

    useEffect(() => {            
        if(userSelected) {
            ref.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
            setNbUserClicked(prevValeur => prevValeur + 1);
        }

    }, [userSelected]);

    useEffect(() => {            
        if (nbUserClicked != 0 && nbUserClicked % 4 === 0) {
            showInterstitial();
        }

    }, [nbUserClicked]);



    useEffect(() => {
        if(countriesList.length === 0) {
            getCountriesApi(currentLanguage).then(
                result => setCountriesList(result)
            );
        }      
        
    }, [countriesList]);

    const {t} = useTranslation();

    const location = useLocation();
    useEffect(() => {
        if(location) {
            if(location.pathname === "/members-in-a-map") {
                setSearchType("map");
            }
        }
        
    }, [location]);

    let marginValue = "35px";
    if (process.env.REACT_APP_IS_IOS_APP === "true") {
        marginValue = "55px";
    }

    const mapOptions = {
        styles: customMapStyles,
    };

    return (
        <Fragment>
            <Helmet>
                <title>Tevif | {t('research')} - {t('in_a_map')}  - {t('find_and_meet_friends')}</title>
                <meta name="description" content={t('search_for_people_meet_contact_women_and_men_around_you')} />
            </Helmet>
            <HeaderTwo />
            <InitializeAndHideBanner />
            <div className="shop-single padding-tb banner-admob-margin" style={width < 576 ? {marginTop: marginValue} : {}}>
                <div className="container">
                    <div className="row g-4 justify-content-center">
                        <div className="col-xl-8 col-12">
                            <AdTevif pageName={'membersInMaps'} />
                            <article>
                                <div className="product-details">
                                    <PositionUser />
                                    <div className="row g-4 align-items-center" ref={mapRef}>
                                        {
                                            isLoaded ? (
                                                <GoogleMap
                                                    mapContainerStyle={containerStyle}
                                                    onLoad={onLoad}
                                                    onUnmount={onUnmount}
                                                    center={mapCenter}
                                                    zoom={mapZoom}
                                                    mapTypeId={'terrain'}
                                                    options={mapOptions}
                                                >
                                                
                                                </GoogleMap>
                                            ) : <></>
                                        }
                                    </div> 
                                </div>
                            </article>
                        </div>
                        <div className="col-xl-4 col-12">
                            <aside>
                                {/** SELECTED USER */}
                                    {   usernameSelected ? (
                                        <div ref={ref}>
                                            <div className="review">
                                                <ul className="review-nav lab-ul DescActive">
                                                    <li className="desc" onClick={() => {setUsernameSelected(""); setUserSelected("");}}>x</li>
                                                </ul>

                                                <div className="widget widget-post">                                                    
                                                    <div className="widget-header">
                                                        <h5>{t('user_selected')}</h5>
                                                    </div>
                                                    {
                                                        spinnerUserSelected ? (
                                                            <TevifSpinner/> 
                                                        ) : (
                                                            <>
                                                            {
                                                                userSelected ? (
                                                                    <ul className="lab-ul widget-wrapper">
                                                                    <Link to={`/profile/${userSelected.username}`}>
                                                                        <li className="d-flex flex-wrap">
                                                                            <div className="post-thumb">
                                                                                 <img 
                                                                                    src={userSelected.publicPathCropedProfilePicture} 
                                                                                    alt={userSelected.username} 
                                                                                />
                                                                            </div>
                                                                            <div className="post-content ps-4">
                                                                                <h6>
                                                                                    {getProfileName(userSelected.username, userSelected.firstName ?? '', userSelected.lastName ?? '', true)}
                                                                                    {userSelected.verified ? (<i className="icofont-check  verified-user" title="Verify Profile"></i>) : null}
                                                                                </h6>
                                                                                <UserData user={userSelected}  />
                                                                                <p>{userSelected.age} {t('years')}</p>
                                                                            </div>
                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            ) : null}
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    ) : null }
                                {/** SELECTED USER */}

                                {/** Filter Map */}
                                <div className="widget search-widget">
                                    <div className="widget-inner">
                                        <div className="widget-title">
                                            <h5>{t('filter')}</h5>
                                        </div>
                                        <div className="widget-content">
                                            <form className="banner-form" onSubmit={handleSubmit}>
                                                <div className="gender">
                                                    <div className="custom-select right w-100">
                                                        <select value={filterGender} onChange={(e)=>{setFilterGender(e.target.value);}}>
                                                            <option value="">{t('gender')}</option>
                                                            <option value="1">{t('male')}</option>
                                                            <option value="2">{t('female')}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="age">
                                                    <div
                                                        className="right d-flex justify-content-between w-100">
                                                        <div className="custom-select">
                                                            <select value={filterAgeFrom} onChange={(e)=>{setFilterAgeFrom(e.target.value);}}>
                                                                <option value="">{t('age_from')}</option>
                                                                {getAges().map((age) => (
                                                                    <option value={age} key={`age-${age}`} >{age}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className="custom-select">
                                                            <select value={filterAgeTo} onChange={(e)=>{setFilterAgeTo(e.target.value);}}>
                                                                <option value="">{t('age_to')}</option>
                                                                {getAges().map((age) => (
                                                                    <option value={age} key={`age-${age}`} >{age}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="city">
                                                    <div className="custom-select right w-100">
                                                        <select value={filterCountry} onChange={(e)=>{setFilterCountry(e.target.value);}}>
                                                            <option value="">{t('all_countries')}</option>
                                                            {countriesList.map(
                                                                c => <option key={c.key ? "country_"+c.key : "country_"+c.id} value={c.id}>
                                                                        {getCountryNameByLang(c, currentLanguage)}
                                                                    </option>
                                                                )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <button>{t('find')}</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {/** Filter Map */}
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            <AppSectionTwo />
            <Footer />
        </Fragment>
    );
}


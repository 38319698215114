import {AppTrackingTransparency} from 'capacitor-plugin-app-tracking-transparency';

export async function  requestPermission() {
    if(process.env.REACT_APP_IS_IOS_APP === 'true') {
      const response = await AppTrackingTransparency.requestPermission();
    
      return response;
    } 
    
    return { status: 'notiosapp' };
}

export  async function getStatus() {
    if(process.env.REACT_APP_IS_IOS_APP === 'true') {
      const response = await AppTrackingTransparency.getStatus();

      // { status: 'authorized' } for example
    
      return response;
    }

    return { status: 'notiosapp' };
}
import { AdMob, BannerAdPosition, BannerAdSize, RewardAdPluginEvents } from '@capacitor-community/admob';
import { Capacitor } from '@capacitor/core';

const isNativePlatform = Capacitor.isNativePlatform();
const {REACT_APP_AD_BANNER_ID, REACT_APP_AD_INTERSTITIAL_ID, REACT_APP_AD_REWARD_VIDEO_ID, REACT_APP_AD_REWARD_ID} = process.env;

export const initializeAdMob = async () => {
  if(!isNativePlatform) {
    return null;
  }

  const { status } = await AdMob.trackingAuthorizationStatus();

  if (status === 'notDetermined') {
      console.log('Display information before ads load first time');
  }

  AdMob.initialize(
    {
        requestTrackingAuthorization: true
    }
  );
}

export const showBanner = async () => {
    if(!isNativePlatform) {
      return null;
    }

    const adId = REACT_APP_AD_BANNER_ID;
    const options = {
        adId,
        adSize: BannerAdSize.BANNER,
        position: BannerAdPosition.BOTTOM_CENTER,
        margin:90
    }

    try {
      await AdMob.showBanner(options);
    } catch(err) {
      
    }
}

export const hideBanner = async () => {
  if(!isNativePlatform) {
    return null;
  }

  try {
    await AdMob.hideBanner();
  } catch (err) {
    
  }
  
}

export const resumeBanner = async () => {
  if(!isNativePlatform) {
    return null;
  }

  try {
    await AdMob.resumeBanner();
  } catch(err) {
    
  }
}


export const showInterstitial = async () => {
    if(!isNativePlatform) {
      return null;
    }

  const adId = REACT_APP_AD_INTERSTITIAL_ID;
  const options = {
    adId: adId
  }

  await AdMob.prepareInterstitial(options);
  await AdMob.showInterstitial();
}

export const showReward = async () => {
  if(!isNativePlatform) {
    return null;
  }

  AdMob.addListener(
    RewardAdPluginEvents.Rewarded, 
    (reward) => {
      console.log('REWARD:', reward);
    }

    );
  
  const adId = REACT_APP_AD_REWARD_ID;
  const options = {
    adId: adId
  }

  await AdMob.prepareRewardVideoAd(options);
  await AdMob.showRewardVideoAd();
}



